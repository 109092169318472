<template>
  <div>
    <p class="has-text-weight-bold is-size-3 mb-5">
      {{ $t("af:application.result.title") }}
    </p>
    <template v-if="isSuccess">
      <application-template
        template="APPLICATION_SUCCESS"
      ></application-template>
    </template>
    <template v-else>
      <application-template
        template="APPLICATION_FAILURE"
      ></application-template>
    </template>

    <b-field class="mt-6">
      <b-button
        id="flowResultButtonFinish"
        type="is-success"
        size="is-large"
        :label="$t('af:application.result.button.finish')"
        :rounded="true"
        :inverted="true"
        icon-left="check"
        @click="finishApplication"
      ></b-button>
    </b-field>
  </div>
</template>

<script>
import flowResultMixin from "../../mixins/flowResultMixin";

export default {
  name: "ApplicationFlowResult",
  mixins: [flowResultMixin],
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  methods: {
    track() {
      if (this.isSuccess) {
        this.$gtag.event("closed_success");
      } else {
        this.$gtag.event("closed_fail");
      }
    },
  },
  mounted() {
    this.track();
  },
};
</script>

<style scoped lang="scss"></style>
